/* eslint-disable global-require */
import universal from 'react-universal-component';
import { setupRouter } from '@cotyorg/ccx-server/router';
import { safeImport } from '@cotyorg/ccx-server/utils';

const AuthentischeBewertungen = universal(
  import('./pages/AuthentischeBewertungen')
);
const ErrorPage = universal(import('./pages/Error'));
const NotFound = universal(import('./pages/NotFound'));
const Home = universal(import('./pages/Home'));
const VoicesLanding = universal(import('./pages/VoicesLanding'));
const VoicesPerson = universal(import('./pages/VoicesPerson'));
const Category = universal(import('./pages/Category'));
const Subcategory = universal(import('./pages/Subcategory'));
const Product = universal(import('./pages/Product'));
const Page = universal(import('./pages/Page'));
const New = universal(import('./pages/New'));
const BestSellers = universal(import('./pages/BestSellers'));
const MakeUpTutorials = universal(import('./pages/MakeUpTutorials'));
const MakeUpTutorialPage = universal(import('./pages/MakeUpTutorialPage'));
const MakeUpArtistPerson = universal(import('./pages/MakeUpArtistPerson'));
const OurBrand = universal(import('./pages/OurBrand'));
const IconicFirsts = universal(import('./pages/IconicFirsts'));
const MakeUpArtists = universal(import('./pages/MakeUpArtists'));
const MaxFactorStory = universal(import('./pages/MaxFactorStory'));
const IconicLooksLanding = universal(import('./pages/IconicLooksLanding'));
const IconicLooksPerson = universal(import('./pages/IconicLooksPerson'));
const OurHeritage = universal(import('./pages/OurHeritage'));
const SimplePageWithCopy = universal(import('./pages/SimplePageWithCopy'));
const LimitedEdition = universal(import('./pages/LimitedEdition'));
const SearchResults = universal(import('./pages/SearchResults'));
const SerravalleOutlet = universal(import('./pages/SerravalleOutlet'));
const PathSelector = universal(import('./pages/FoundationFinder/PathSelector'));
const FindMyShade = universal(import('./pages/FoundationFinder/FindMyShade'));
const MatchMyShade = universal(import('./pages/FoundationFinder/MatchMyShade'));
const Result = universal(import('./pages/FoundationFinder/Result'));
const StoreLocator = universal(import('./pages/StoreLocator'));
const VirtualTryOn = universal(import('./pages/VirtualTryOn'));
const TermsAndConditions = universal(import('./pages/TermsAndConditions'));
const Terms = universal(import('./pages/Terms'));
const SocialMediaHouseRules = universal(
  import('./pages/SocialMediaHouseRules')
);
const ConcorsoSanremo = universal(import('./pages/ConcorsoSanremo'));
const Kampagnengesicht = universal(import('./pages/Kampagnengesicht'));
const Concorsi = universal(import('./pages/Concorsi'));
const WhereToBuy = universal(import('./pages/WhereToBuy'));
const Teilnahmebedingungen = universal(import('./pages/Teilnahmebedingungen'));
const FarbRaumePage = universal(import('./pages/FarbRaumePage'));
const Unicef = universal(import('./pages/Unicef'));
const LightTheWay = universal(import('./pages/LightTheWay'));
const MatchFinder = universal(import('./pages/MatchFinder'));
const MaxfactorPopupStore = universal(import('./pages/MaxfactorPopupStore'));

const routerConfig = {
  helperComponents: {
    ErrorComponent: ErrorPage,
  },

  defaultPages: {
    homeOverrides: {
      component: Home,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/home').default
      ),
    },
    notFoundOverrides: {
      component: NotFound,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/not-found').default
      ),
    },
    categoryOverrides: {
      component: Category,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/category').default
      ),
    },
    subcategoryOverrides: {
      component: Subcategory,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/subcategory').default
      ),
    },
    productOverrides: {
      component: Product,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/product').default
      ),
    },
  },

  additionalRoutes: [
    {
      path: '/:locale/max-factor-voices',
      exact: true,
      id: 'voices',
      title: 'Voices',
      component: VoicesLanding,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/voices-landing-page').default
      ),
    },
    {
      path: '/:locale/max-factor-voices/:person',
      exact: true,
      id: 'voices-person-page',
      title: 'Voices Person Page',
      component: VoicesPerson,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/voices-person-page').default
      ),
    },
    {
      path: '/:locale/new',
      exact: true,
      id: 'new',
      title: 'New',
      component: New,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/new').default
      ),
    },
    {
      path: '/:locale/best-sellers',
      exact: true,
      id: 'best-sellers',
      title: 'Best Sellers',
      component: BestSellers,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/best-sellers').default
      ),
    },
    {
      path: '/:locale/makeup-tutorials',
      exact: true,
      id: 'makeup-tutorials',
      title: 'Make Up Tutorials',
      component: MakeUpTutorials,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/makeup-tutorials').default
      ),
    },
    {
      path: '/:locale/makeup-tutorials/:tutorialSlug',
      exact: true,
      id: 'makeup-tutorial-page',
      title: 'Make Up Tutorial Page',
      component: MakeUpTutorialPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/makeup-tutorial-page').default
      ),
    },
    {
      path: '/:locale/makeup-artists',
      exact: true,
      id: 'makeup-artists',
      title: 'Make Up Artists',
      component: MakeUpArtists,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/makeup-artists').default
      ),
    },
    {
      path: '/:locale/makeup-artists/:artistSlug',
      exact: true,
      id: 'make-up-artist-person-page',
      title: 'Make Up Artist Person Page',
      component: MakeUpArtistPerson,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/make-up-artist-person').default
      ),
    },
    {
      path: '/:locale/our-brand',
      exact: true,
      id: 'our-brand',
      title: 'Our Brand',
      component: OurBrand,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/our-brand').default
      ),
    },
    {
      path: '/:locale/our-brand/10-iconic-firsts',
      exact: true,
      id: 'iconic-firsts',
      title: '10 Iconic Firsts',
      component: IconicFirsts,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/iconic-firsts').default
      ),
    },
    {
      path: '/:locale/our-brand/max-factor-story',
      exact: true,
      id: 'max-factor-story',
      title: 'Max Factor Story',
      component: MaxFactorStory,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/max-factor-story').default
      ),
    },
    {
      path: '/:locale/our-brand/iconic-looks/:iconicSlug',
      exact: true,
      id: 'iconic-looks-person-page',
      title: 'Iconic Looks Person Page',
      component: IconicLooksPerson,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/iconic-looks-person').default
      ),
    },
    {
      path: '/:locale/our-brand/iconic-looks',
      exact: true,
      id: 'iconic-looks-landing-page',
      title: 'Iconic Looks Landing Page',
      component: IconicLooksLanding,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/iconic-looks-landing').default
      ),
    },
    {
      path: '/:locale/our-brand/our-heritage',
      exact: true,
      id: 'our-heritage-page',
      title: 'Our Heritage Page',
      component: OurHeritage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/our-heritage-page').default
      ),
    },
    {
      path: '/:locale/impressum',
      exact: true,
      id: 'impressum',
      title: 'Impressum',
      component: SimplePageWithCopy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/impressum').default
      ),
    },
    {
      path: '/en-gb/giveaway-terms-and-conditions',
      exact: true,
      id: 'giveaway-terms-and-conditions',
      title: 'Page',
      component: Page,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/page').default
      ),
    },
    {
      path: '/:locale/limited-edition',
      exact: true,
      id: 'limited-edition',
      title: 'Limited Edition Page',
      component: LimitedEdition,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/limited-edition').default
      ),
    },
    {
      path: '/:locale/maxfactor_serravalle_outlet',
      exact: true,
      id: 'seravalle-outlet',
      title: 'Seravalle Outlet Page',
      component: SerravalleOutlet,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/serravalle-outlet').default
      ),
    },
    {
      path: '/:locale/search',
      exact: false,
      id: 'search-results-page',
      title: 'Search Results Page',
      component: SearchResults,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/search-results').default
      ),
    },
    {
      path: '/:locale/foundation-finder',
      exact: true,
      id: 'foundation-finder-path-selector',
      title: 'Foundation Finder Path Selector',
      component: PathSelector,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/path-selector')
            .default
      ),
    },
    {
      path: '/:locale/foundation-finder/find-my-shade',
      exact: false,
      id: 'foundation-finder-find-my-shade',
      title: 'Foundation Finder Find My Shade',
      component: FindMyShade,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/find-my-shade')
            .default
      ),
    },
    {
      path: '/:locale/foundation-finder/match-my-shade',
      exact: false,
      id: 'foundation-finder-match-my-shade',
      title: 'Foundation Finder Match My Shade',
      component: MatchMyShade,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/match-my-shade')
            .default
      ),
    },
    {
      path: '/:locale/foundation-finder/result',
      exact: false,
      id: 'foundation-finder-result',
      title: 'Foundation Finder Result',
      component: Result,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/result').default
      ),
    },
    {
      path: '/:locale/store-locator',
      exact: true,
      id: 'store-locator',
      title: 'Store locator',
      component: StoreLocator,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/store-locator-page').default
      ),
    },
    {
      path: '/:locale/terms-and-conditions',
      exact: true,
      id: 'terms-and-conditions',
      title: 'Terms And Conditions',
      component: TermsAndConditions,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/terms-and-conditions').default
      ),
    },
    {
      path: '/:locale/social-media-house-rules',
      exact: true,
      id: 'social-media-house-rules',
      title: 'Social Media House Rules',
      component: SocialMediaHouseRules,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/social-media-house-rules').default
      ),
    },
    {
      path: '/:locale/virtual-try-on',
      exact: true,
      id: 'virtual-try-on',
      title: 'Virtual Try On',
      component: VirtualTryOn,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/virtual-try-on-page').default
      ),
    },

    // Localized routes
    {
      path: '/:locale/concorso-sanremo',
      exact: true,
      id: 'concorso-sanremo',
      title: 'Concorso Sanremo',
      component: ConcorsoSanremo,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/concorso-sanremo').default
      ),
    },
    {
      path: '/:locale/concorsi',
      exact: true,
      id: 'concorsi',
      title: 'Concorsi',
      component: Concorsi,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/concorsi').default
      ),
    },
    {
      path: '/:locale/where-to-buy',
      exact: true,
      id: 'where-to-buy-page',
      title: 'Where to buy Page',
      component: WhereToBuy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/where-to-buy').default
      ),
    },
    {
      path: '/:locale/teilnahmebedingungen-kampagnengesicht-2022',
      exact: true,
      id: 'teilnahmebedingungen',
      title: 'teilnahmebedingungen',
      component: Teilnahmebedingungen,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/teilnahmebedingungen').default
      ),
    },
    {
      path: '/:locale/kampagnengesicht-2022',
      exact: true,
      id: 'kampagnengesicht',
      title: 'Kampagnengesicht',
      component: Kampagnengesicht,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/kampagnengesicht').default
      ),
    },
    {
      path: '/:de-de/nutzungsbedingungen',
      exact: true,
      id: 'terms',
      title: 'Terms',
      component: Terms,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/terms').default
      ),
    },
    {
      path: '/:de-de/make-up-finder',
      exact: true,
      id: 'foundation-finder-path-selector',
      title: 'Foundation Finder Path Selector',
      component: PathSelector,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/path-selector')
            .default
      ),
    },
    {
      path: '/:de-de/make-up-finder/find-my-shade',
      exact: false,
      id: 'foundation-finder-find-my-shade',
      title: 'Foundation Finder Find My Shade',
      component: FindMyShade,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/find-my-shade')
            .default
      ),
    },
    {
      path: '/:de-de/make-up-finder/match-my-shade',
      exact: false,
      id: 'foundation-finder-match-my-shade',
      title: 'Foundation Finder Match My Shade',
      component: MatchMyShade,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/match-my-shade')
            .default
      ),
    },
    {
      path: '/:de-de/make-up-finder/result',
      exact: false,
      id: 'foundation-finder-result',
      title: 'Foundation Finder Result',
      component: Result,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/foundation-finder/result').default
      ),
    },

    // This path is only to be used by ReactRouter Config, accessing this page directly is an edge case
    {
      path: '/:locale/error',
      exact: true,
      id: 'error',
      title: 'Error',
      component: ErrorPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/error').default
      ),
    },
    {
      path: '/:locale/authentische-bewertungen',
      exact: true,
      id: 'authentische-bewertungen',
      title: 'Authentische Bewertungen',
      component: AuthentischeBewertungen,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/authentische-bewertungen').default
      ),
    },
    {
      path: '/:locale/farbraume',
      exact: true,
      id: 'farbraume',
      title: 'Farbraume',
      component: FarbRaumePage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/farbraum').default
      ),
    },
    {
      path: '/:locale/light-the-way/unicef',
      exact: true,
      id: 'unicef',
      title: 'Unicef',
      component: Unicef,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/unicef').default
      ),
    },
    {
      path: '/:locale/light-the-way',
      exact: true,
      id: 'light-the-way',
      title: 'LightTheWay',
      component: LightTheWay,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/light-the-way').default
      ),
    },
    {
      path: '/de-de/frauen100',
      exact: true,
      id: 'frauen100',
      title: 'Frauen100',
      component: LightTheWay,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/light-the-way').default
      ),
    },
    {
      path: '/:locale/match-finder',
      exact: true,
      id: 'match-finder',
      title: 'MatchFinder',
      component: MatchFinder,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/match-finder').default
      ),
    },
    {
      path: '/:locale/maxfactor-popup-store',
      exact: true,
      id: 'maxfactor-popup-store',
      title: 'MaxfactorPopupStore',
      component: MaxfactorPopupStore,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/maxfactor-popup-store').default
      ),
    },
  ],

  routesToRemove: [
    // 'example', // NB: there is no example route, so this won't remove anything - but this is the mechanism
  ],
};

export default setupRouter(routerConfig);
