export const SET_STEP = 'BEAMLY/CCX/SET_STEP';
export const SET_ANSWERS = 'BEAMLY/CCX/SET_ANSWERS';
export const SET_SELECTORS_OPTIONS = 'BEAMLY/CCX/SET_SELECTORS_OPTIONS';
export const SET_SELECTED_VARIANT_UID = 'BEAMLY/CCX/SET_SELECTED_VARIANT_UID';
export const SET_RESULT_VARIANT = 'BEAMLY/CCX/SET_RESULT_VARIANT';
export const SET_IS_LOADING = 'BEAMLY/CCX/SET_IS_LOADING';

export const setStep = ({ currentStepNumber }) => ({
  type: SET_STEP,
  currentStepNumber,
});

export const setAnswers = ({ answers }) => ({
  type: SET_ANSWERS,
  answers,
});

export const setSelectorsOptions = ({ selectorsOptions }) => ({
  type: SET_SELECTORS_OPTIONS,
  selectorsOptions,
});

export const setSelectedVariantUid = ({ selectedVariantUid }) => ({
  type: SET_SELECTED_VARIANT_UID,
  selectedVariantUid,
});

export const setResultVariant = ({ resultVariant }) => ({
  type: SET_RESULT_VARIANT,
  resultVariant,
});

export const setIsLoading = ({ isLoading }) => ({
  type: SET_IS_LOADING,
  isLoading,
});

export const getVariantByUid =
  ({ variantUid, locale, selectedVariantUid, firstSelectorRef }) =>
  (dispatch) =>
    fetch(`/${locale}/api/foundation-finder-variant/${variantUid}`)
      .then((res) => res.json())
      .then((res) => {
        dispatch(
          setResultVariant({
            resultVariant: res[0][0],
          })
        );

        /* eslint-disable no-param-reassign */
        firstSelectorRef.value = 'default';
        /* eslint-enable no-param-reassign */

        window.location.href = `/${locale}/${
          window.location.pathname.split('/')[2]
        }/result?uid=${selectedVariantUid}`;
      });
