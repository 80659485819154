import { SET_STEP, SET_ANSWERS, SET_RESULT_VARIANT_FMS } from './actions';

const currentStepNumberState = (state, { currentStepNumber }) => ({
  ...state,
  currentStepNumber,
});

const answersState = (state, { answers }) => ({
  ...state,
  answers,
});

const resultVariantState = (state, { resultVariant }) => ({
  ...state,
  resultVariant,
});

const reducer = {
  [SET_STEP]: currentStepNumberState,
  [SET_ANSWERS]: answersState,
  [SET_RESULT_VARIANT_FMS]: resultVariantState,
};

const initialState = {
  currentStepNumber: 1,
  answers: [],
  resultVariant: null,
};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
