import R from 'ramda';
import { updateContent } from '@cotyorg/ccx-server/modules/content/actions';
import { buildQuizOptionContentCard } from '../helpers/answers';

export const SET_STEP = 'BEAMLY/CCX/SET_STEP';
export const SET_ANSWERS = 'BEAMLY/CCX/SET_ANSWERS';
export const SET_RESULT_VARIANT_FMS = 'BEAMLY/CCX/SET_RESULT_VARIANT_FMS';

export const setStep = ({ currentStepNumber }) => ({
  type: SET_STEP,
  currentStepNumber,
});

export const setAnswers = ({ answers }) => ({
  type: SET_ANSWERS,
  answers,
});

export const setResultVariant = ({ resultVariant }) => ({
  type: SET_RESULT_VARIANT_FMS,
  resultVariant,
});

export const getVariantsQuizQuestion =
  ({ answersUids, currentStepNumber, locale }) =>
  (dispatch) =>
    fetch(`/${locale}/api/foundation-finder/${R.join(',', answersUids)}`)
      .then((res) => res.json())
      .then((res) => {
        const variants = R.map(
          (foundationFinderVariant) =>
            buildQuizOptionContentCard({
              foundationFinderVariant,
              locale,
            }),
          R.prop(0, res)
        );

        if (R.length(variants) === 1) {
          dispatch(
            setResultVariant({
              resultVariant: R.path([0, 'resultVariant'], variants),
            })
          );

          window.location.href = `/${locale}/${
            window.location.pathname.split('/')[2]
          }/result?uid=${R.path([0, 'id'], variants)}`;
        } else {
          dispatch(
            updateContent({
              key: 'cardoGroup',
              data: {
                shadesAnswers: variants,
              },
            })
          );
          dispatch(setStep({ currentStepNumber }));
        }
      });
