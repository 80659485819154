export const SHOW_MORE_CARD_LIST = 'BEAMLY/CCX/SHOW_MORE_CARD_LIST';
export const HIDE_SHOW_MORE_CARD_LIST = 'BEAMLY/CCX/HIDE_SHOW_MORE_CARD_LIST';

export const showMore = () => ({
  type: SHOW_MORE_CARD_LIST,
});

export const hideShowMore = () => ({
  type: HIDE_SHOW_MORE_CARD_LIST,
});
