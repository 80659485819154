import { SHOW_MORE_CARD_LIST, HIDE_SHOW_MORE_CARD_LIST } from './actions';

const showMore = (state) => ({
  ...state,
  isOpen: true,
});

const hideShowMore = (state) => ({
  ...state,
  showMoreIsHidden: true,
});

const reducer = {
  [SHOW_MORE_CARD_LIST]: showMore,
  [HIDE_SHOW_MORE_CARD_LIST]: hideShowMore,
};

const initialState = {};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
