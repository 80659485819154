import { combineReducers } from 'redux';
import accordionReducer from '@cotyorg/ccx-molecules/Accordion/modules';
import carouselReducer from '@cotyorg/ccx-organism-carousel/modules';
import cookieReducer from '@cotyorg/ccx-organism-cookie/modules';
import dragSwipeReducer from '@cotyorg/ccx-utils/js/components/DragSwipe/modules';
import headerReducer from '@cotyorg/ccx-organism-header/modules';
import navigationReducer from '@cotyorg/ccx-organism-navigation/modules';
import countrySelectorReducer from '@cotyorg/ccx-organism-country-selector/modules';
import pictureReducer from '@cotyorg/ccx-atoms/Picture/modules';
import lazyLoadReducer from '@cotyorg/ccx-utils/js/components/LazyLoad/modules';
import productSwatchReducer from '@cotyorg/ccx-organism-product-swatch/modules';
import { content as contentReducer } from '@cotyorg/ccx-server/modules';
import breakpointReducer from '@cotyorg/ccx-utils/js/components/BreakpointProvider/modules';
import sliderReducer from '@cotyorg/ccx-molecules/Slider/modules';
import videoReducer from '@cotyorg/ccx-organism-video/modules';
import searchReducer from '@cotyorg/ccx-organism-search/modules';
import cardoListReducer from '@cotyorg/ccx-organism-cardo-list/modules';
import subcategoryNavigationReducer from '../organisms/SubcategoryNavigation/modules';
import productRetailersReducer from '../organisms/ProductRetailers/modules';
import cardListReducer from '../organisms/CardList/modules';
import findMyShadeReducer from '../pages/FoundationFinder/FindMyShade/modules';
import matchMyShadeReducer from '../pages/FoundationFinder/MatchMyShade/modules';

const rootReducer = combineReducers({
  accordions: accordionReducer,
  carousels: carouselReducer,
  content: contentReducer,
  cookie: cookieReducer,
  breakpoints: breakpointReducer,
  countrySelector: countrySelectorReducer,
  dragSwipe: dragSwipeReducer,
  header: headerReducer,
  lazyLoads: lazyLoadReducer,
  navigation: navigationReducer,
  pictures: pictureReducer,
  productSwatch: productSwatchReducer,
  sliders: sliderReducer,
  subcategoryNavigation: subcategoryNavigationReducer,
  productRetailers: productRetailersReducer,
  cardList: cardListReducer,
  cardoList: cardoListReducer,
  status: (state = {}) => state,
  locale: (state = {}) => state,
  presentationLocale: (state = {}) => state,
  search: searchReducer,
  videos: videoReducer, // TODO: [1] this should be video?
  findMyShade: findMyShadeReducer,
  matchMyShade: matchMyShadeReducer,
});

export default rootReducer;
