import R from 'ramda';

export const buildQuizOptionContentCard = ({
  foundationFinderVariant,
  locale,
}) => {
  const image = R.path(['shade_image', 0], foundationFinderVariant);
  const variant = R.path(['product_variant', 0], foundationFinderVariant);
  const variantName = R.prop('name', variant);

  return {
    id: R.prop('uid', foundationFinderVariant),
    title: variantName,
    link: {
      text: variantName,
      url: `/${locale}/${
        window.location.pathname.split('/')[2]
      }/result?uid=${R.prop('uid', foundationFinderVariant)}`,
      openInNewWindow: false,
    },
    alt: R.prop('alt_text', image),
    imageSrc: R.path(['xs', 'url'], image),
    imageSrcSet: [
      {
        name: 'small',
        src: R.path(['xs', 'url'], image),
      },
      {
        name: 'medium',
        src: R.path(['sm', 'url'], image),
      },
      {
        name: 'large',
        src: R.path(['md', 'url'], image),
      },
      {
        name: 'xlarge',
        src: R.path(['lg', 'url'], image),
      },
    ],
    feature: 'actual_shade',
    value: R.prop('title', variant),
    resultVariant: variant,
  };
};

export const filterVariantsOptions = ({ findMyShadeVariants, answers }) =>
  R.reduce(
    (variants, element) =>
      R.filter(
        (variant) => R.includes(element, R.prop('answers', variant)),
        variants
      ),
    findMyShadeVariants,
    answers
  );

export const getExistingAnswers = ({ variants, currentStepNumber }) =>
  R.uniq(
    R.map(
      (variant) => R.path(['answers', currentStepNumber - 1], variant),
      variants
    )
  );

export const filterVariantsOptionsByUniqueAnswers = ({
  variants,
  uniqAnswers,
}) =>
  R.filter(
    (variant) => R.includes(R.prop('uid', variant), uniqAnswers),
    variants
  );

export const getAnalyticsAnswers = ({
  answersOptionsCount,
  questionKey,
  currentStepNumber,
  cardoIndex,
  cardoGroup,
  quizAnswers,
  shadesQuestionNumber,
}) => {
  if (currentStepNumber === 1 || currentStepNumber === shadesQuestionNumber) {
    return R.toLower(
      R.path([questionKey, cardoIndex, 'link', 'text'], cardoGroup)
    );
  }
  if (currentStepNumber === 4) {
    return answersOptionsCount === 2
      ? R.path([`q4${answersOptionsCount}`, cardoIndex], quizAnswers)
      : R.path([`q4${answersOptionsCount}`, cardoIndex], quizAnswers);
  }
  return R.path([`q${currentStepNumber}`, cardoIndex], quizAnswers);
};
