import {
  SET_STEP,
  SET_ANSWERS,
  SET_SELECTORS_OPTIONS,
  SET_SELECTED_VARIANT_UID,
  SET_RESULT_VARIANT,
  SET_IS_LOADING,
} from './actions';

const currentStepNumberState = (state, { currentStepNumber }) => ({
  ...state,
  currentStepNumber,
});

const answersState = (state, { answers }) => ({
  ...state,
  answers,
});

const selectorsOptionsState = (state, { selectorsOptions }) => ({
  ...state,
  selectorsOptions,
});

const selectedVariantUidState = (state, { selectedVariantUid }) => ({
  ...state,
  selectedVariantUid,
});

const resultVariantState = (state, { resultVariant }) => ({
  ...state,
  resultVariant,
});

const isLoadingState = (state, { isLoading }) => ({
  ...state,
  isLoading,
});

const reducer = {
  [SET_STEP]: currentStepNumberState,
  [SET_ANSWERS]: answersState,
  [SET_SELECTORS_OPTIONS]: selectorsOptionsState,
  [SET_SELECTED_VARIANT_UID]: selectedVariantUidState,
  [SET_RESULT_VARIANT]: resultVariantState,
  [SET_IS_LOADING]: isLoadingState,
};

const initialState = {
  currentStepNumber: 0,
  answers: [],
  selectorsOptions: [],
  selectedVariantUid: '',
  resultVariant: null,
  isLoading: false,
};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
